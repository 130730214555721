<template lang="pug">
.resume
  b-table(
    :data='devices'
    :loading='loading'
    narrowed
    hoverable
    mobile-cards
    paginated
    per-page='5'
    detailed
  )
    template(slot-scope='props')
      b-table-column(field='estado')
        device-status(:value='props.row.status')
      b-table-column(field='id' label='Identificador') {{ props.row.id }}
      b-table-column(field='name' label='Nombre') {{ props.row.name }}
      b-table-column(field='registerDate' label='Fecha de registro') {{ props.row.registerDate }}
    template(slot='empty')
      .no-data-found
</template>

<script>
import { mapActions, mapState } from 'vuex'
import DeviceStatus from '../components/devices/DeviceStatus'

export default {
  components: { DeviceStatus },
  created () {
    this.getDevices()
  },
  computed: {
    ...mapState({
      loading: state => state.devices.loading,
      error: state => state.devices.error,
      devices: state => state.devices.devices
    })
  },
  methods: {
    ...mapActions({
      getDevices: 'devices/getDevices'
    })
  }
}
</script>
